import React, { useEffect, useState } from "react";
import { Api } from "../../api/apiCotacoes";
import { Api as ApiMunicipios } from "../../api/apiMunicipios";
import { useParams } from "react-router-dom";
import { Visualizar } from "./utils/visualizar";
import { VisualizarDetalhes } from "./utils/visualizarDetalhes";
import Icone from "../../assets/icones/icone.png"
import { toast } from "react-hot-toast";
import axios from "axios";
import { baseUrlApi } from "../../api/baseUrl";
import { AlertResposta } from "../../components/loading/alert";

function CotacoesPrecos() {
  document.title = "Corretor Parceiro";
  const [ramo, setRamo] = useState("");
  useEffect(() => {
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("saude")) {
      setRamo("2");
    } else if (url.includes("odontologico")) {
      setRamo("1");
    }
  }, []);

  const [modalFiltro, setModalFiltro] = useState(false);
  const [info, setInfo] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [calculo, setCalculo] = useState([]);
  const [calculoDetalhe, setCalculoDetalhe] = useState([]);
  const [modalVisualizarDetalhe, setModalVisualizarDetalhe] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [loadingFiltros, setLoadingFiltros] = useState(false);
  const [calculoFiltro, setCalculoFiltro] = useState([])
  const [valores, setValores] = useState(false)
  const [loadingDetalhes, setLoadingDetalhe] = useState(false)
  const [visualizar, setVisualizar] = useState([])
  const [total, setTotal] = useState()
  const [exibirTodos, setExibirTodos] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)


  useEffect(() => {
    handleCalculo(id)
  }, [id])
  useEffect(() => {
    setCalculoFiltro(calculo)
  }, [calculo])

  const toastConfig = {
    position: "top-right",
    duration: 3000,
    style: { backgroundColor: "#fff" },
  };

  const handleMunicipios = async (idEstado, idTipoOperadora) => {
    let response = await ApiMunicipios.listagem({ idEstado, idTipoOperadora });
    if (!response?.error) {
      let find = response?.find
      setMunicipios(find);
    }

  };
  const handleCalculo = async (id, filtros) => {
    if (filtros) {
      setLoadingFiltros(true)
    } else {
      setLoading(true)
    }

    let response = await Api.calculo({ id: id, cobertura: filtros?.cobertura, coparticipacao: filtros?.coparticipacao, contratacao: filtros?.contratacao, reembolso: filtros?.reembolso, municipio: filtros?.municipio, acomodacao: filtros?.acomodacao });
    if (response?.listagem?.length > 0) {
      let find = { ...response?.cotacao, valores: response?.valores, vidas: response?.vidas }
      handleMunicipios(find?.estado?.id, find?.idtipooperadora)
      setCalculoDetalhe(find);
      setCalculo(response?.listagem.map((item) => { return { visualizar: true, ...item } }));
      let idtabelas = []
      response.listagem?.map((item) => {
        item.idTabelas?.map((item) => {
          idtabelas.push({ idtabela: item, visualizar: false })
        })
      })
      setSelecteds((prevSelecteds) => [...prevSelecteds, ...idtabelas]);
    } else {
      setCalculo([]);
      setCalculoDetalhe({})
      setSelecteds([]);
    }
    if (filtros) {
      setLoadingFiltros(false)
    } else {
      setLoading(false)
    }
  };
  const handleOperadora = async (item, index) => {
    setExibirTodos(false)
    setLoadingDetalhe(true);
    setModalVisualizarDetalhe(true)
    handleClickAll(false)
    let dados = [...selecteds]
    let selectedsNew = dados.map((map) => { return { ...map, visualizar:( map.visualizar && item.idTabelas?.some((som) => som == map.idtabela) )} })

    setSelecteds(selectedsNew)
    if (item?.tabelas ? item?.tabelas?.some((tabela) => !(tabela?.pagamento?.id || tabela?.observacao?.id || tabela?.carencia?.id) ? true : false) : true) {

      let ids = item?.idTabelas?.map((item) => { return item })

      let response = await Api.calculoDetalhe({ idOperadoras: [item?.id], ids, id, entidadeProfissoes: calculoDetalhe?.entidadeProfissoes })
      if (!response?.error) {
        let calculos = [...calculo]
        let produtos = response?.listagem
        item.tabelas = produtos
        calculos[index].tabelas = item?.tabelas
        setCalculo(calculos)
      }
    }
    gerarTabela([item], dados?.array, true);
    setLoadingDetalhe(false);
  };
  const handleOperadoraMultiplos = async (items, status = false) => {
    setExibirTodos(true)
    setModalVisualizarDetalhe(true)
    setLoadingDetalhe(true);

    let ids = items.map((item) => { return item.visualizar ? item?.tabelas ? item?.tabelas?.map((tabela) => { return !(tabela?.pagamento?.id || tabela?.observacao?.id || tabela?.carencia?.id) ? tabela?.id : null }) : item.idTabelas.map((id) => { return id }) : false }).flat().filter((fill) => fill);

    if (ids?.length > 0) {
      let select = [...selecteds]
      let selectedsNew = select.map((map) => { return { ...map, visualizar: (map.visualizar && ids.some((som) => som == map?.idtabela) )} })
      setSelecteds(selectedsNew);

      let idOperadoras = items.filter((fill) => fill?.visualizar).map((item) => { return item?.id }).flat();
      let response = await Api.calculoDetalhe({ ids, id, idOperadoras, entidadeProfissoes: calculoDetalhe?.entidadeProfissoes })
      if (!response?.error) {
        let calculos = [...calculo]
        let produtos = response.listagem
        let dados = items.map((item) => { return { ...item, tabelas: item.idTabelas.map((map) => { return produtos.find((somProd) => somProd.id == map) }) } })
        calculos = dados
        setCalculo(calculos)
        gerarTabela(dados, status);
      }
    }
    setLoadingDetalhe(false);
  };

  const generatePDF = async () => {
    setLoadingPdf(true)
    let ids = selecteds.filter((fill) => fill?.visualizar).map((item) => { return item.idtabela }).flat().filter((fill) => fill);

    axios.post(`${baseUrlApi}/cotacao/gerarPdf/${id}`, { ids }, { responseType: 'blob' })
      .then(response => {
        // Verifica se o blob não está vazio (opcional)
        if (!response.data || response.data.size === 0) {
          setLoadingPdf(false)
          return;
        }
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'arquivo.pdf');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileURL);
        setLoadingPdf(false)
      })
      .catch(error => {
      });

  }

  const enviarEmail = async () => {
    toast.loading('Estamos enviado a cotação para seu email.', toastConfig);
    let ids = selecteds.filter((fill) => fill?.visualizar).map((item) => { return item.idtabela }).flat().filter((fill) => fill);
    let response = await Api.enviarEmail({ ids, id })
    if (!response?.error) {
      toast.success(response?.message, toastConfig);
    } else {
      toast.error(response?.message, toastConfig)
    }
  }

  const gerarTabela = (dados, status) => {


    let array = []
    dados?.filter((fil) => fil.status == 1)?.map((tabela) => {
      array.push({
        visualizar: true, id: tabela.id, operadora: tabela.nome, imagem: tabela.imagem,
        tabelas: { nome: "", id: calculoDetalhe?.id, estado: calculoDetalhe?.estado, produto: [], faixas: [], observacoes: [], areacomercializacao: [], dependente: [], documento: [], pagamento: [], rede: [], reembolso: [], carencia: [], entidades: [], coparticipacoes: [] }
      })
      tabela.tabelas?.map((item) => {
        let indexOperado = array.findIndex((arrayItem) => arrayItem?.id == tabela?.id)
        if (status ? status : selecteds.some((item3) => item3?.idtabela === item?.id)) {
          array[indexOperado].tabelas.produto.push({
            id: item.id, nome: item.nome, cobertura: item.cobertura, acomodacao: item.acomodacao, coparticipacao: item.coparticipacao, possuereembolso: item.possuereembolso,
            percentual: item.percentual, vidas: `${item.minvidas} a ${item.maxvidas}`, faixas: item.tabelafaixas.sort((pessoa1, pessoa2) => pessoa1.id - pessoa2.id), contratacao: item?.contratacao,
            precovida: item.precovida, item, status: item.status
          });
          if (item?.coparticipaccao?.id) {
            if (item.coparticipaccao?.id && !array[indexOperado].tabelas.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id)) {
              array[indexOperado].tabelas.coparticipacoes.push({ id: item.coparticipaccao.id, item: item.coparticipaccao, produto: [item.nome] })
            } else {
              array[indexOperado].tabelas.coparticipacoes.some((arr) => arr.id == item.coparticipaccao?.id ? arr.produto.push(item.nome) : '')
            }
          }
          if (item?.observacao?.id && !array[indexOperado].tabelas.observacoes.some((arr) => arr.id == item.observacao.id)) {
            array[indexOperado].tabelas.observacoes.push({ id: item.observacao?.id, item: item.observacao, produto: [item.nome] });
          } else {
            array[indexOperado].tabelas.observacoes.some((arr) =>
              arr.id == item.observacao?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (item?.areacomercializacao?.id && !array[indexOperado].tabelas.areacomercializacao.some((arr) => arr.id == item.areacomercializacao?.id)) {
            array[indexOperado].tabelas.areacomercializacao.push({
              id: item.areacomercializacao.id, descricoes: item.areacomercializacao?.areacomercializacaodescricaos, produto: [item.nome], municipios: item.areacomercializacaoestadosmunicipios
            });
          } else {
            array[indexOperado]?.tabelas.areacomercializacao.some((arr) =>
              arr.id == item.areacomercializacao?.id
                ? arr.produto.push(item.nome)
                : ""
            );
          }
          if (item.dependente?.id && !array[indexOperado].tabelas.dependente.some((arr) => arr.id == item.dependente?.id)) {
            array[indexOperado].tabelas.dependente.push({
              id: item.dependente?.id,
              item: item.dependente,
              produto: [item?.nome]
            });
          } else {
            array[indexOperado].tabelas.dependente.some((arr) =>
              arr.id == item.dependente?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (item.documento?.id && !array[indexOperado].tabelas.documento.some((arr) => arr.id == item.documento?.id)) {
            array[indexOperado].tabelas.documento.push({
              id: item.documento.id,
              item: item.documento,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.documento.some((arr) =>
              arr.id == item.documento?.id ? arr.produto.push(item.nome) : ""
            );
          }

          if (item.pagamento?.id && !array[indexOperado].tabelas.pagamento.some((arr) => arr.id == item.pagamento?.id)) {
            array[indexOperado].tabelas.pagamento.push({
              id: item.pagamento.id,
              item: item.pagamento,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.pagamento.some((arr) =>
              arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : ""
            );
          }

          if (item.carencia?.id && !array[indexOperado].tabelas.carencia.some((arr) => arr.id == item.carencia?.id)) {
            array[indexOperado].tabelas.carencia.push({
              id: item.carencia.id,
              item: item.carencia,
              produto: [item.nome]
            });
          } else {
            array[indexOperado].tabelas.carencia.some((arr) =>
              arr.id == item.carencia?.id ? arr.produto.push(item.nome) : ""
            );
          }
          if (item.reembolso?.id && !array[indexOperado].tabelas.reembolso.some((arr) => arr.id == item.reembolso?.id)) {
            array[indexOperado].tabelas.reembolso.push({ id: item.reembolso.id, item: item.reembolso, produto: [item.nome] });
          } else {
            array[indexOperado].tabelas.reembolso.some((arr) =>
              arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : ""
            );
          }
          item?.grupoentidade?.map((item2) => {
            if (item2?.identidade && !array[indexOperado]?.tabelas?.entidades?.some((arr) => arr.id == item2.identidade)) {
              array[indexOperado]?.tabelas?.entidades?.push({
                id: item2.identidade
                , item: item2, produto: [item.nome]
              });
            } else {
              array[indexOperado]?.tabelas?.entidades?.some((arr) => arr.id == item2.identidade ? (!arr.produto.some((prod) => prod == item.nome) ? arr.produto.push(item.nome) : '') : "");
            }
          })
        }
      })
    });

    setInfo(array);
  };

  useEffect(() => {
    setCalculoFiltro(calculo)
    setVisualizar(calculo.map(() => { return true }))
  }, [calculo])

  const handleClick = (idtabela, index) => {
    if (selecteds.find((item) => (item.idtabela == idtabela))) {
      let item = [...selecteds];
      item[index].visualizar = !item[index].visualizar
      setSelecteds(item);
    }
  };
  const handleClickAll = (status) => {
    let newSelecteds = [...selecteds]
    newSelecteds = newSelecteds.map((item) => { return { ...item, visualizar: status } })
    setSelecteds(newSelecteds);
  };
  const handleClickOperadoraSelectAll = async () => {
    let select = [...selecteds];
    let calFilter = calculoFiltro.map((item) => { return { ...item, visualizar: true } })
    select = select.map((item) => { return { ...item, visualizar: true } })
    setCalculo(calFilter)
    setSelecteds(select);
  };
  const handleClickOperadoraDeselectAll = async () => {
    let select = [...selecteds];
    let calFilter = calculoFiltro.map((item) => { return { ...item, visualizar: false } })
    select = select.map((item) => { return { ...item, visualizar: false } })
    setCalculo(calFilter)
    setSelecteds(select);
  };
  const handleClickOperadoraSelect = async (operadora) => {
    let select = [...selecteds];
    let calFilter = [...calculoFiltro]
    let indexOperado = calFilter.findIndex((find) => find.id == operadora?.id)
    calFilter[indexOperado].visualizar = !calFilter[indexOperado].visualizar
    select = select.map((sele) => { return { ...sele, visualizar: !sele.visualizar } })
    setCalculo(calFilter)
    setSelecteds(select);
  };

  const handleClickOperadoraDeselect = async (item) => {
    let select = [...selecteds];
    let calFilter = [...calculoFiltro]
    let indexOperado = calFilter.findIndex((find) => find.id == operadora?.id)
    calFilter[indexOperado].visualizar = !calFilter[indexOperado].visualizar
    select = select.map((sele) => { return { ...sele, visualizar: !sele.visualizar } })
    setCalculo(calFilter)
    setSelecteds(select);
  };
  const handleVisualizarCotacoes = () => {
    setTotal(false)
    handleOperadoraMultiplos(calculoFiltro, false);
  };
  const handleVisualizarCotacoesTodas = () => {
    setTotal(true)
    handleOperadoraMultiplos(calculoFiltro, true);
  };

  const traducaoCobertura = [{ value: 'Hospitalar', label: 'Hospitalar' },
  { value: 'Urgência + Emergência', label: 'Urgência e Emergência' },
  { value: 'Ambulatorial', label: 'Ambulatorial' },
  { value: 'Completo (AMB + HOSP + OBST)', label: 'Amb + Hosp Com Obstetrícia' },
  { value: 'Completo (AMB + HOSP + OBST) + Odontológico', label: 'Amb + Hosp + Odonto Com Obstetrícia' },
  { value: 'Hospitalar + Odontológico', label: 'Hospitalar + Odontológico' },
  { value: 'Ambulatorial + Odontológico', label: 'Ambulatorial Com Odonto' },
  { value: 'Hospitalar + Obstetrícia', label: 'Hospitalar + Obstetrícia' },
  { value: 'Ambulatorial + Hospitalar', label: 'Amb + Hosp Sem Obstetrícia' },
  { value: 'Amb + Hosp + Odonto sem Obstetrícia', label: 'Amb + Hosp + Odonto sem Obstetrícia' }]
  const traducaoCoparticipacao = [{ value: 'Sim', label: 'Com Coparticipação' }, { value: 'Não', label: 'Sem Coparticipação' }, { value: 'Parcial', label: 'Com Coparticipação Parcial' }]
  const traducaoContratacao = [{ value: 'OPCIONAL', label: 'Opcional' }, { value: 'COMPULSÓRIO', label: 'Compulsório' }]
  const traducaoReembolso = [{ value: 'Sim', label: 'Com Reembolso' }, { value: 'Não', label: 'Sem Reembolso' }]

  const [municipio, setMunicipio] = useState()
  const [cobertura, setCobertura] = useState()
  const [coparticipacao, setCoparticipacao] = useState()
  const [acomodacao, setAcomodacao] = useState()
  const [contratacao, setContratacao] = useState()
  const [reembolso, setReembolso] = useState()


  const [municipioList, setMunicipioList] = useState([])
  const [coberturaList, setCoberturaList] = useState([])
  const [coparticipacaoList, setCoparticipacaoList] = useState([])
  const [acomodacaoList, setAcomodacaoList] = useState([])
  const [contratacaoList, setContratacaoList] = useState([])
  const [reembolsoList, setReembolsoList] = useState([])

  const handleFiltro = () => {
    let coberturaTraduzido = (cobertura?.value)
    let coparticipacaoTraduzido = (coparticipacao?.value == 'Sim' ? ['Sim', 'Total'] : coparticipacao?.value)
    let contratacaoTraduzido = (contratacao?.value == 'AMBOS' ? '' : contratacao?.value)
    let reembolsoTraduzido = (reembolso?.value)
    let municipioTraduzido = (municipio?.value)
    let acomodacaoTraduzido = (acomodacao?.value)

    handleCalculo(id, { cobertura: coberturaTraduzido, coparticipacao: coparticipacaoTraduzido, contratacao: contratacaoTraduzido, reembolso: reembolsoTraduzido, municipio: municipioTraduzido, acomodacao: acomodacaoTraduzido })
  }
  const handleListagemFiltros = () => {

    let municipiodArray = []
    let coberturasarray = []
    let contratacaoarray = []
    let acomodacaoArray = []
    let coparticipacoesarray = []
    let reembolsoArray = []

    calculo?.filter((fill) => fill?.status == 1).map((item) => {
      item.municipios?.map((map) => {
        if (!municipiodArray.some((som) => som?.id == map)) {
          let municipio = municipios?.find((muni) => muni?.id == map)
          if (municipio?.id) {
            municipiodArray.push(municipio)
          }
        }
      })
      item.possuereembolso?.map((map) => {
        if (map && !reembolsoArray.some((som) => som == map)) {
          reembolsoArray.push(map)
        }
      })
      item.acomodacao?.map((map) => {
        if (map && !acomodacaoArray.some((som) => som == map)) {
          acomodacaoArray.push(map)
        }
      })
      item.cobertura?.map((map) => {
        if (map && !coberturasarray.some((som) => som == map)) {
          coberturasarray.push(map)
        }
      })
      item.contratacao?.map((map) => {
        if (map && !contratacaoarray.some((som) => som == map)) {
          contratacaoarray.push(map)
        }
      })
      item.coparticipacao?.map((map) => {
        if (map && !coparticipacoesarray.includes(map)) {
          if ((map == 'Sim' || map == 'Total')) {
            if (!coparticipacoesarray.some((item) => (item == 'Sim' || item == 'Total'))) {
              coparticipacoesarray.push(map)
            }
          } else {
            coparticipacoesarray.push(map)
          }
        }
      })
    })
    if (municipioList?.length == 0) {
      setMunicipioList(municipiodArray)
    }
    if (coberturaList?.length == 0) {
      setCoberturaList(coberturasarray)
    }
    if (coparticipacaoList?.length == 0) {
      setCoparticipacaoList(coparticipacoesarray)
    }
    if (acomodacaoList?.length == 0) {
      setAcomodacaoList(acomodacaoArray)
    }
    if (contratacaoList?.length == 0) {
      setContratacaoList(contratacaoarray)
    }
    if (reembolsoList?.length == 0) {
      setReembolsoList(reembolsoArray)
    }
  }
  useEffect(() => {
    if (municipio || cobertura || coparticipacao || acomodacao || contratacao || reembolso) {
      handleFiltro()
    }
  }, [municipio, cobertura, coparticipacao, acomodacao, contratacao, reembolso])
  useEffect(() => {
    handleListagemFiltros()
  }, [calculo, municipios])
  return (
    <section className="about-two ">
      <AlertResposta />
      <div className="container" >
        <div className="" style={{ width: "100%", marginTop: '20px' }}>

          {(!loading) && (
            <div style={{ marginBottom: 20 }} >

              <Visualizar handleOperadora={handleOperadora} calculoDetalhe={calculoDetalhe} calculo={calculo} calculoFiltro={calculoFiltro} handleVisualizarCotacoes={handleVisualizarCotacoes} handleClickOperadoraSelect={handleClickOperadoraSelect} handleClickOperadoraDeselect={handleClickOperadoraDeselect} loadingDetalhes={loadingDetalhes} valores={valores} infoDependente={calculoDetalhe?.dependentes} handleVisualizarCotacoesTodas={handleVisualizarCotacoesTodas} municipios={municipios} setCalculoFiltro={setCalculoFiltro} handleClickOperadoraSelectAll={handleClickOperadoraSelectAll} handleClickOperadoraDeselectAll={handleClickOperadoraDeselectAll} setInfo={setInfo} traducaoCobertura={traducaoCobertura} traducaoCoparticipacao={traducaoCoparticipacao} traducaoContratacao={traducaoContratacao} traducaoReembolso={traducaoReembolso} coberturaList={coberturaList} municipioList={municipioList} coparticipacaoList={coparticipacaoList} acomodacaoList={acomodacaoList} contratacaoList={contratacaoList} reembolsoList={reembolsoList} setMunicipio={setMunicipio} setCobertura={setCobertura} setCoparticipacao={setCoparticipacao} setAcomodacao={setAcomodacao} setContratacao={setContratacao} setReembolso={setReembolso}
                cobertura={cobertura} municipio={municipio} coparticipacao={coparticipacao} acomodacao={acomodacao} reembolso={reembolso} contratacao={contratacao} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} visualizar={visualizar} setVisualizar={setVisualizar} loadingFiltros={loadingFiltros} />

              <VisualizarDetalhes id={id} selecteds={selecteds} setModalVisualizarDetalhe={setModalVisualizarDetalhe} infoDependente={calculoDetalhe?.dependentes} info={info} modalVisualizarDetalhe={modalVisualizarDetalhe} calculoDetalhe={calculoDetalhe} handleClick={handleClick}
                gerarTabela={gerarTabela} loadingDetalhes={loadingDetalhes} setInfo={setInfo} total={total} visualizar={visualizar} handleClickAll={handleClickAll} generatePDF={generatePDF} exibirTodos={exibirTodos} loadingPdf={loadingPdf} enviarEmail={enviarEmail} />
            </div>
          )}
          {(loading || loadingFiltros) && (
            <div
              className={`col-lg-12 col-sm-12 item`}
              style={{
                height: "20vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                width: "100%",
                zIndex: 99999999999999,
              }}
            >
              <img src={Icone} className="rotating-image" />
            </div>
          )}

          {calculo?.length == 0 && !loading && !loadingFiltros &&
            <div className={`col-lg-12 col-sm-12 item `} style={{ minHeight: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              Nenhum cálculo encontrado
            </div>
          }
        </div>
      </div>
    </section>)
}

export default CotacoesPrecos
